import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slice/searchPageSlice';

export const filterFields = {
  Location: 'Location',
  ProgramName: 'ProgramName',
  SampleName: 'SampleName'
};

const initialFilterState = {
  Location: null,
  ProgramName: null,
  SampleName: null || ''
};

const initialPageState = {
  page: 0,
  pageSize: 100,
  sortColumn: 'SampleName',
  sortDirection: SortDirection.Asc,
};

export const sliceName = 'companySummaryReport';
const companySummaryReportSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState,
  initialPageState
});

export const companySummaryReportActions = companySummaryReportSlice.actions;
export default companySummaryReportSlice.reducer;
