import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slice/searchPageSlice';

export const filterFields = {
  Title: 'Title',
  ActionStatus: 'ActionStatus',
  ActionType: 'ActionType',
  AssignedTo: 'AssignedTo',
  CreatedOnFrom: 'CreatedOnFrom',
  CreatedOnTo: 'CreatedOnTo',
  DueDateFrom: 'DueDateFrom',
  DueDateTo: 'DueDateTo'
};

const initialFilterState = {
  Title: '',
  ActionStatus: null,
  ActionType: null,
  AssignedTo: null,
  CreatedOnFrom: '',
  CreatedOnTo: '',
  DueDateFrom: '',
  DueDateTo: ''
};

const initialPageState = {
  page: 0,
  pageSize: 100,
  sortColumn: 'Title',
  sortDirection: SortDirection.Asc,
};

export const sliceName = 'actionsSearch';

const actionsSearchSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState,
  initialPageState
});

export const actionsSearchActions = actionsSearchSlice.actions;
export default actionsSearchSlice.reducer;
