import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Sidebar from './Navigation/Sidebar';
import Navbar from './Navigation/NavBar';
import LegalBar from './LegalBar';

const LayoutRoot = styled('div')(({ theme }) => {
  const svgPath = theme.palette.mode === 'dark' ? '/static/images/wave_d.svg' : '/static/images/wave.svg';
  return {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
    background: `url('${svgPath}') no-repeat bottom`,
    backgroundSize: '100% auto'
  };
});

const LayoutWrapper = styled('div')(() => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: '64px',
  // [theme.breakpoints.up("lg")]: {
  //   paddingLeft: "280px",
  // },
}));

const LayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  height: '100%',
  flexDirection: 'column'
});

const LayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch',
  // paddingBottom: '48px',
});

const MainLayout = () => {
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const layoutContentRef = useRef(null);

  useEffect(() => {
    if (layoutContentRef.current) {
      layoutContentRef.current.scrollTop = 0;
    }
  }, [location.pathname]);

  const toggleSideBarHandler = () => {
    setIsSidebarOpen((prepValue) => !prepValue);
  };

  return (
    <LayoutRoot>
      <Navbar onSidebarMobileOpen={toggleSideBarHandler} open={isSidebarOpen} />
      <Sidebar onSidebarClose={() => setIsSidebarOpen(false)} isSidebarOpen={isSidebarOpen} />

      {/* sx={{ paddingLeft: paddingLeft }} */}

      <LayoutWrapper>
        <LayoutContainer>
          <LayoutContent ref={layoutContentRef}>
            <Outlet />
          </LayoutContent>
          <LegalBar linkColor="text.primary" hideBottomBorder />
        </LayoutContainer>
      </LayoutWrapper>
    </LayoutRoot>
  );
};

export default MainLayout;
