import React, { useState } from 'react';
import {
  Box, Button, Dialog, DialogContent,
  DialogTitle, Divider, IconButton, TextField, Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CheckIcon from '@mui/icons-material/Check';
import { DIALOG_RESPONSE, useGlobalDialogContext } from './GlobalDialog';
import IF from '../ui/IF';
import { LoadingButton } from '@mui/lab';

const ConfirmationDialog = ({
  isOpen,
  title,
  message,
  showCancel = false,
  responseCallback,
  reasonRequired,
}) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalDialogContext();
  const [actionReason, setActionReason] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const closeDialog = () => {
    hideModal();
  };

  const dialogResponseHandler = async (response) => {
    if (response === 'YES') {
      setIsLoading(true);
    }
    const willCloseDialog = await responseCallback(response, actionReason);
    if (response === 'YES') {
      setIsLoading(false);
    }
    if (willCloseDialog !== false) {
      hideModal();
    }
  };

  const handleChange = (evt) => {
    const { value } = evt.target;
    setActionReason(value);
  };

  return (
    <Dialog
      fullWidth
      open={isOpen}
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
    >
      <DialogTitle>
        <Box sx={{ justifyContent: 'space-between', display: 'flex' }}>
          <Typography color="textPrimary" variant="h6">
            {title}
          </Typography>
          <IconButton size="small" onClick={closeDialog}>
            <CloseIcon fontSize="medium" />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', flexDirection: 'row' }}>
          <Box sx={{ flex: 3 }}>
            <HelpOutlineIcon color="action" fontSize="large" sx={{ height: '70px', width: '70px' }} />
          </Box>
          <Typography color="textSecondary" variant="subtitle2" sx={{ flex: 9 }}>
            {message}
          </Typography>
        </Box>
        <IF condition={reasonRequired}>
          <Box sx={{ display: 'flex', mt: 1 }}>
            <TextField
              fullWidth
              required
              multiline
              rows={2}
              label="Reason"
              placeholder="Enter Reason"
              onChange={handleChange}
              value={actionReason}
              size="small"
            />
          </Box>
        </IF>
      </DialogContent>
      <Divider />
      <Box p={1} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <LoadingButton
          size="small"
          variant="contained"
          startIcon={<CheckIcon size="small" />}
          sx={{ mr: 0.5 }}
          loadingPosition="start"
          loading={isLoading}
          onClick={() => dialogResponseHandler(DIALOG_RESPONSE.YES)}
          disabled={reasonRequired ? !actionReason.trim().length : false || isLoading}
        >
          {t('Btn_Yes')}
        </LoadingButton>
        <Button
          size="small"
          variant="text"
          sx={{ mr: 0.5 }}
          onClick={() => dialogResponseHandler(DIALOG_RESPONSE.NO)}
        >
          {t('Btn_No')}
        </Button>
        <IF condition={showCancel}>
          <Button
            size="small"
            variant="text"
            onClick={closeDialog}
          >
            {t('Btn_Cancel')}
          </Button>
        </IF>
      </Box>
    </Dialog>
  );
};

export default ConfirmationDialog;
