// import { CssBaseline, ThemeProvider } from '@mui/material';
import { CssBaseline, ThemeProvider } from '@mui/material';

import { Toaster } from 'react-hot-toast';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
// import SettingsDrawer from "./components/ui/SettingsDrawer";
import routes from './routes';
import useSettings from './hooks/useSettings';
import { createCustomTheme } from './theme';
import RTL from './components/ui/RTL';

import './i18n/i18n';
// import useScrollReset from './hooks/useScrollReset';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { initialize } from './store/actions/authActions';
import { GlobalModal } from './components/GlobalDialog/GlobalDialog';
import apiConfig from './services/apiConfig';
import ReleaseNotesDialog from './components/Dialogs/ReleaseNotesDialog/ReleaseNotesDialog';
import useAuth from './hooks/useAuth';

export const storedAckedVersion = localStorage.getItem('Version_Acked');
export const currentVersion = apiConfig.Version;

function App() {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth();

  const { settings } = useSettings();
  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  // New browser router that supports Data APIs
  const router = createBrowserRouter(routes);

  // useScrollReset();

  const showReleaseNotes = isAuthenticated && (!storedAckedVersion || (storedAckedVersion !== currentVersion));
  useEffect(() => {
    const abortController = new AbortController();

    dispatch(initialize(abortController));

    return () => {
      abortController.abort();
    };
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={settings.direction}>
        <CssBaseline />
        <Toaster position="top-center" />
        {/* <SettingsDrawer /> */}
        <GlobalModal>
          <RouterProvider router={router} />
        </GlobalModal>
      </RTL>
      {showReleaseNotes && false && <ReleaseNotesDialog />}
    </ThemeProvider>
  );
}

export default App;
