import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slice/searchPageSlice';

export const filterFields = {
  MethodName: 'MethodName',
  Active: 'Active',
};

const initialFilterState = {
  MethodName: '',
  Active: true,
};

const initialPageState = {
  page: 0,
  pageSize: 100,
  sortColumn: 'MethodName',
  sortDirection: SortDirection.Asc,
};
export const sliceName = 'methodSearch';

const MethodSearchSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState,
  initialPageState
});

export const MethodSearchActions = MethodSearchSlice.actions;
export default MethodSearchSlice.reducer;
