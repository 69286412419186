export const ObjectTypes = {
  // Grid Columns
  ProgramSearchGrid: 'Grid_Columns_ProgramSearch',
  CycleSearchGrid: 'Grid_Columns_CycleSearch',
  AuditSearchGrid: 'Grid_Columns_AuditSearch',
  NAListSearchGrid: 'Grid_Columns_NAListSearch',
  ActionsSearchGrid: 'Grid_Columns_ActionSearch',
  EmailsSearchGrid: 'Grid_Columns_EmailSearch',
  CycleSearchNAListSearchGrid: 'Grid_Columns_CycleSearchNAListSearch',
  EnrollmentSearchGrid: 'Grid_Columns_EnrollmentSearch',
  OrganizationSearchGrid: 'Grid_Columns_OrganizationSearch',
  ProductsSearchGrid: 'Grid_Columns_ProductsSearch',
  CountriesSearchGrid: 'Grid_Columns_CountriesSearch',
  ListSearchGrid: 'Grid_Columns_ListSearch',
  MethodSearchGrid: 'Grid_Columns_MethodSearch',
  LocationSearchGrid: 'Grid_Columns_LocationSearch',
  LabSummaryReportSearchGrid: 'Grid_Columns_Lab_Summary',
  OutstandingCARsSearchGrid: 'Grid_Columns_OutstandingCARs',
  UnitSearchGrid: 'Grid_Columns_UnitSearch',
  IgnoreListSearchGrid: 'Grid_Columns_IgnoreListSearch',
  RegionListSearchGrid: 'Grid_Columns_RegionSearch',
  ContactsSearchGrid: 'Grid_Column_ContactSearch',
  RoleSearchGrid: 'Grid_Columns_RoleSearch',
  SubjectMatterExpertiseGrid: 'Grid_Columns_SubjectMatterExpertise',
  UserSearchGrid: 'Grid_Columns_UserSearch',
  AnnouncementsSearchGrid: 'Grid_Columns_Announcements',
  MyAnnouncementsSearchGrid: 'Grid_Columns_MyAnnouncements',
  GroupSearchGrid: 'Grid_Columns_GroupSearch',
  AccreditationBodiesGrid: 'Grid_Columns_AccreditationBodies',
  DashboardLayout: 'DashboardLayout',
  MethodEquivalenceGrid: 'Grid_Columns_MethodEquivalence',
  CompanySummaryReportGrid: 'Grid_Columns_CompanySummaryReport',
  DataExportSearchGrid: 'Grid_Columns_DataExport',
  UserFilter_TrendingGraphSearch: 'UserFilter_TrendingGraphSearch',
  ZCarPercentageReportGrid: 'Grid_Columns_ZCarPercentageReport',
  EditEnrollmentNAListDialogGrid: 'Grid_Columns_EditEnrollmentNAListDialogGrid',

  Grid_Columns_ReviewResults: 'Grid_Columns_ReviewResults',
  Grid_Columns_StatsSearch: 'Grid_Columns_StatsSearch',
  Grid_Columns_CompanySummaryReportView: 'Grid_Columns_CompanySummaryReportView',
  Grid_Columns_LocationSummaryReportView: 'Grid_Columns_LocationSummaryReportView',
  AssetSearchGrid: 'Grid_Columns_AssetSearch',
  // End Grid Columns

  // UserFilter
  ProgramSearchUserFilter: 'UserFilter_ProgramSearch',
  CycleSearchUserFilter: 'UserFilter_CycleSearch',
  AuditSearchUserFilter: 'UserFilter_AuditSearch',
  NAListSearchUserFilter: 'UserFilter_NAListSearch',
  EnrollmentSearchUserFilter: 'UserFilter_EnrollmentSearch',
  SponsorSearchUserFilter: 'UserFilter_SponsorSearch',
  OrganizationSearchSavedUserFilter: 'OrganizationSearchSavedUserFilter',
  ProductsSearchSavedUserFilter: 'ProductsSearchSavedUserFilter',
  CountriesSearchSavedUserFilter: 'CountriesSearchSavedUserFilter',
  ListSearchUserFilter: 'UserFilter_ListSearch',
  LocationSearchUserFilter: 'UserFilter_LocationSearch',
  LabSummarySearchUserFilter: 'UserFilter_LabSummarySearch',
  TrendingGraphSearchUserFilter: 'UserFilter_TrendingGraphSearch',
  OutstandingCARsSearchUserFilter: 'UserFilter_OutstandingCARsSearch',
  UnitSearchUserFilter: 'UserFilter_UnitSearch',
  IgnoreListSearchUserFilter: 'UserFilter_IgnoreListSearch',
  RegionSearchSavedUserFilter: 'RegionSearchSavedUserFilter',
  ContactSearchUserFilter: 'UserFilter_ContactSearch',
  RoleSearchUserFilter: 'RoleSearchUserFilter',
  SubjectMatterExpertiseSearchUserFilter: 'UserFilter_SubjectMatterExpertiseSearch',
  UserSearchUserFilter: 'UserSearchUserFilter',
  AnnouncementsUserFilter: 'UserFilter_Announcements',
  MyAnnouncementsUserFilter: 'UserFilter_MyAnnouncements',
  GroupsSearchUserFilter: 'UserFilter_GroupsSearch',
  AccreditationBodiesUserFilter: 'UserFilter_AccreditationBodies',
  MethodEquivalenceUserFilter: 'UserFilter_MethodEquivalence',
  CompanySummaryReportUserFilter: 'UserFilter_CompanySummaryReport',
  DataExportUserFilter: 'UserFilter_DataExport',
  ReportGradingUserFilter: 'UserFilter_ReportGrading',
  ZCarPercentageReportUserFilter: 'UserFilter_ZCarPercentageReport',
  TrendingSearchPresetUserFilter: 'TrendingSearchPresetUserFilter',
  TrendingSearchUserFilter: 'TrendingSearchUserFilter',
  ActionsSearchUserFilter: 'ActionsSearchUserFilter',
  EmailsSearchUserFilter: 'EmailsSearchUserFilter',
  AssetSearchUserFilter: 'UserFilter_AssetSearch',
  // END UserFilter

  // SearchFilter
  SearchFilter_Programs: 'SearchFilter_Programs',
  SearchFilter_CycleSearch: 'SearchFilter_CycleSearch',
  SearchFilter_CycleSearchNAList: 'SearchFilter_CycleSearchNAList',
  SearchFilter_NAList: 'SearchFilter_NAList',
  SearchFilter_Enrollment: 'SearchFilter_Enrollment',
  SearchFilter_Method: 'SearchFilter_Method',
  MethodSearchSavedUserFilter: 'MethodSearchSavedUserFilter',
  SearchFilter_Organization: 'SearchFilter_Organization',
  SearchFilter_List: 'SearchFilter_List',
  SearchFilter_Products: 'SearchFilter_Products',
  SearchFilter_Location: 'Search_Filter_Location',
  SearchFilter_LabSummary: 'Search_Filter_LabSummary',
  SearchFilter_OutstandingCARs: 'Search_Filter_OutstandingCARs',
  SearchFilter_TrendingGraphs: 'Search_Filter_TrendingGraphs',
  SearchFilter_Countries: 'SearchFilter_Countries',
  SearchFilter_Unit: 'SearchFilter_Unit',
  SearchFilter_IgnoreList: 'SearchFilter_IgnoreList',
  SearchFilter_Region: 'SearchFilter_Region',
  SearchFilter_Contacts: 'SearchFilter_Contacts',
  SearchFilter_Role: 'SearchFilter_Role',
  SearchFilter_SubjectMatterExpertise: 'SearchFilter_SubjectMatterExpertise',
  SearchFilter_Announcements: 'SearchFilter_Announcements',
  SearchFilter_MyAnnouncements: 'SearchFilter_MyAnnouncements',
  SearchFilter_User: 'SearchFilter_User',
  SearchFilter_GroupSearch: 'SearchFilter_GroupSearch',
  SearchFilter_AccreditationBodies: 'SearchFilter_AccreditationBodies',
  SearchFilter_MethodEquivalence: 'SearchFilter_MethodEquivalence',
  SearchFilter_CompanySummaryReport: 'SearchFilter_CompanySummaryReport',
  SearchFilter_DataExport: 'SearchFilter_DataExport',
  SearchFilter_ReportGrading: 'SearchFilter_ReportGrading',
  SearchFilter_ZCarPercentageReport: 'SearchFilter_ZCarPercentageReport',
  SearchFilter_ActionsSearch: 'SearchFilter_Actions',
  SearchFilter_EmailsSearch: 'SearchFilter_Emails',
  SearchFilter_Asset: 'SearchFilter_Asset',
  // End SearchFilter
};
