import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slice/searchPageSlice';

export const filterFields = {
  Name: 'Name',
  Email: 'Email',
  Function: 'Function',
  SubjectMatterExpertise: 'SubjectMatterExpertise',
  Region: 'Region',
  Subregion: 'Subregion',
  Location: 'Location',
  ContactType: 'ContactType',
  Active: 'Active',
};

const initialFilterState = {
  Name: '',
  Email: '',
  Function: '',
  SubjectMatterExpertise: null,
  Region: null,
  Subregion: null,
  Location: null,
  ContactType: null,
  Active: true,
};

const initialPageState = {
  page: 0,
  pageSize: 100,
  sortColumn: 'Name',
  sortDirection: SortDirection.Asc,
};

export const sliceName = 'contactsSearch';
const contactsSearchSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState,
  initialPageState,
});

export const contactsSearchActions = contactsSearchSlice.actions;
export default contactsSearchSlice.reducer;
