import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slice/searchPageSlice';

export const filterFields = {
  Recipient: 'Recipient',
  Subject: 'Subject',
  Location: 'Location',
  EmailType: 'EmailType',
  EmailStatus: 'EmailStatus',
  CreatedOnFrom: 'CreatedOnFrom',
  CreatedOnTo: 'CreatedOnTo',
};

const initialFilterState = {
  Recipient: '',
  Subject: '',
  Location: null,
  EmailType: null,
  EmailStatus: null,
  CreatedOnFrom: '',
  CreatedOnTo: ''
};

const initialPageState = {
  page: 0,
  pageSize: 100,
  sortColumn: 'CreatedOn',
  sortDirection: SortDirection.Desc,
};

export const sliceName = 'emailsSearch';
const emailsSearchSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState,
  initialPageState
});

export const emailsSearchActions = emailsSearchSlice.actions;
export default emailsSearchSlice.reducer;
