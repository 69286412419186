import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import React from 'react';

export const sanitizeHtml = (html, opts = {}) => parse(DOMPurify.sanitize(html), {
  ...{
    // eslint-disable-next-line consistent-return
    replace: (domNode) => {
      if (domNode.attribs && domNode.attribs.class === 'remove') {
        return <></>;
      }
    },
  },
  ...opts,
});
