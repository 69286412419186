import axios from 'axios';

export const httpGet = async (axiosInstance, baseUrl, queryValueObj) => {
  const queryValues = Object.keys(queryValueObj).map((key) => ({ name: key, val: queryValueObj[key] }));
  const queryParams = Array.isArray(queryValues)
    ? queryValues
      .filter((x) => {
        if (typeof x.val === 'boolean') {
          return true;
        }

        if (typeof x.val === 'string') {
          return !!x.val;
        }
        return x.val != null;
      })
      .map(({ name, val }) => {
        if (typeof val === 'string') {
          return `${name}=${encodeURIComponent(val.trim())}`;
        }

        if (Array.isArray(val)) {
          return val.map((arrayItem) => `${name}=${encodeURIComponent(arrayItem)}`).join('&');
        }

        return `${name}=${encodeURIComponent(val)}`;
      }) : [];

  const queryParamVal = queryParams.join('&');
  const response = await axiosInstance.get(`${baseUrl}?${queryParamVal}`);

  return response.data;
};

export const getFilenameFromHttpResponse = (response) => {
  let filename = '';
  const disposition = response.headers.get('Content-Disposition');
  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }

  return filename;
};

export const httpPost = async (axiosInstance, baseUrl, queryValueObj, abortController) => {
  const response = await axiosInstance.post(`${baseUrl}`, queryValueObj,
    {
      signal: abortController?.signal,
    });

  return response.data;
};

export const fetchHandler = async (axiosInstance, baseUrl, queryValueObj, abortController) => {
  try {
    return httpGet(axiosInstance, baseUrl, queryValueObj, abortController);
  } catch (e) {
    if (!axios.isCancel(e)) {
      throw e;
    }
    throw new Error(`Error ${baseUrl}`);
  }
};

export const postHandler = async (axiosInstance, baseUrl, queryValueObj, abortController) => {
  try {
    return httpPost(axiosInstance, baseUrl, queryValueObj, abortController);
  } catch (e) {
    if (!axios.isCancel(e)) {
      throw e;
    }
    throw new Error(`Error ${baseUrl}`);
  }
};
