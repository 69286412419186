import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slice/searchPageSlice';

export const filterFields = {
  Name: 'Name',
  Active: 'Active',
};

const initialFilterState = {
  Name: '',
  Active: true,
};

const initialPageState = {
  page: 0,
  pageSize: 100,
  sortColumn: 'MethodEquivalenceName',
  sortDirection: SortDirection.Asc,
};
export const sliceName = 'methodEquivalenceSearch';
const MethodEquivalenceSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState,
  initialPageState
});

export const MethodEquivalenceActions = MethodEquivalenceSlice.actions;
export default MethodEquivalenceSlice.reducer;
