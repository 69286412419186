import { useCallback } from 'react';
import { useSelector } from 'react-redux';

const useAuth = () => {
  const { isAuthenticated, isValidatingToken, loginUser, impersonatedUser } = useSelector((state) => state.auth);
  let {
    assignedCountries,
    assignedRegions,
    assignedLocations,
    assignedBusinessLines,
    dateFormat,
    dateTimeFormat,
    permissionSet,
    customPermissions
  } = loginUser || {};

  const { token, username, user } = loginUser || {};
  const { UserID } = user || {};

  let timezone = user ? user.Timezone : '';

  if (impersonatedUser) {
    assignedCountries = impersonatedUser.assignedCountries;
    assignedRegions = impersonatedUser.assignedRegions;
    assignedLocations = impersonatedUser.assignedLocations;
    assignedBusinessLines = impersonatedUser.assignedBusinessLines;
    dateFormat = impersonatedUser.dateFormat;
    dateTimeFormat = impersonatedUser.dateTimeFormat;
    permissionSet = impersonatedUser.permissionSet;

    customPermissions = impersonatedUser.customPermissions;
    if (impersonatedUser.user) {
      timezone = impersonatedUser.user.Timezone;
    }
  }

  if (!dateFormat) {
    dateFormat = 'mm-dd-yyyy';
  }

  const isAuthorized = useCallback(
    ({ permissionCode, right }) => {
      if (!permissionSet || !permissionCode) {
        return false;
      }

      const permissionOptions = permissionSet[permissionCode.toLowerCase()];
      if (!Array.isArray(permissionOptions) || permissionOptions.length === 0) {
        return false;
      }

      return permissionOptions.includes(right);
    },
    [permissionSet]
  );

  const hasCustomFunction = useCallback(
    (customFunctionCode) => {
      if (!Array.isArray(customPermissions) || customPermissions.length <= 0
        || !customFunctionCode) {
        return false;
      }

      return customPermissions.includes(customFunctionCode.toLocaleLowerCase());
    },
    [customPermissions]
  );

  return {
    isAuthenticated,
    isValidatingToken,
    user,
    token,
    username,
    permissionSet,
    assignedCountries,
    assignedRegions,
    assignedLocations,
    assignedBusinessLines,
    dateFormat,
    dateTimeFormat,
    userId: UserID,
    timezone,
    isAuthorized,
    hasCustomFunction
  };
};

export default useAuth;
