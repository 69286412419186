import apiConfig from '../apiConfig';
import { getAxiosInstance } from '../../utils/axiosApi';
import axios from 'axios';

const axiosInstance = getAxiosInstance({
  baseUrl: apiConfig.PTPAPIUrl,
});

const searchFavorites = async (
  type
) => {
  try {
    const response = await axiosInstance.get(
      `/UserFavoriteSetting/all/type/${type}`
    );

    return response.data;
  } catch (e) {
    if (!axios.isCancel(e)) {
      throw e;
    }
  }

  return {};
};

const saveUserFavorites = async (requestPayload) => {
  try {
    const response = await axiosInstance.post(
      'UserFavoriteSetting/insert',
      requestPayload
    );

    return response.data;
  } catch (error) {
    throw new Error('Error save user favorite');
  }
};

const deleteUserFavorites = async (existingFavoriteItem) => {
  try {
    const response = await axiosInstance.post(
      `UserFavoriteSetting/delete?userFavoriteSettingId=${existingFavoriteItem}`,
    );

    return response.data;
  } catch (error) {
    throw new Error('Error delete user favorite');
  }
};

const UserFavoriteApi = {
  searchFavorites,
  saveUserFavorites,
  deleteUserFavorites
};

export default UserFavoriteApi;
