import { createSlice } from '@reduxjs/toolkit';
import { getVisibleColumns } from '../../../../services/ObjectConfiguration/ObjectConfiguration.service';
import { LoadingStatus, SortDirection } from '../../../../utils/constants';

export const filterFields = {
  Program: 'Program',
  Method: 'Method',
  Parameter: 'Parameter',
  Unit: 'Unit',
  NAReason: 'NAReason',
  Explanation: 'Explanation',
  NA: 'ALL',
  Location: 'Location',
  Approved: 'ALL',
  Active: 'Action',
  NAList: 'NAList'
};

const applySaveFilterHandler = (state, action) => {
  const fields = action.payload;
  if (!Array.isArray(fields) || fields.length === 0) {
    return;
  }

  const fieldObj = {};
  fields.forEach(({ Field, Value, Text }) => {
    fieldObj[Field] = Value;
    if (Text) {
      fieldObj[`${Field}_Text`] = Text;
    }
  });

  [...Object.values(filterFields)].forEach((fieldName) => {
    const fieldValue = fieldObj[fieldName];
    state.filter[fieldName] = fieldValue || '';
  });
};

const initialFilterState = {
  Program: null,
  Location: null,
  NA: 'All',
  Explanation: '',
  Method: null,
  Parameter: null,
  Unit: null,
  NAReason: null,
  Approved: 'All',
  Action: true,
  NAList: 'All',
};

const initialPageState = {
  page: 0,
  pageSize: 100,
  sortColumn: 'LocationName',
  sortDirection: SortDirection.Asc,
};

const initialState = {
  filter: initialFilterState,
  tableInfo: initialPageState,

  naList: [],
  totalNAListCount: 0,
  loadingStatus: LoadingStatus.Idle, // idle, pending, success, failed

  page: 0,
  sort: null,

  isFilterVisible: true,

  visibleGridColumns: [],
  mergedColumns: [],
  loadingGridColumnStatus: LoadingStatus.Idle,

  isSavedFilterLoaded: false,
  savedFilterList: [],
};

const naListSearchSlice = createSlice({
  name: 'naListSearch',
  initialState,
  reducers: {
    showHideFilter(state, action) {
      state.isFilterVisible = action.payload;
    },
    updateFilterValue(state, action) {
      const { propName, propValue } = action.payload;
      state.filter[propName] = propValue;
    },
    resetSearchFilter(state) {
      state.filter = { ...initialFilterState };
    },
    resetSearchFiltersAndPagination(state, action) {
      state.filter = { ...initialFilterState, ...action.payload };
      state.tableInfo = { ...initialPageState };
    },
    updatePageInfoField(state, action) {
      const { propName, propValue } = action.payload;
      state.tableInfo[propName] = propValue;
    },
    setPage(state, action) {
      state.tableInfo.page = action.payload;
    },
    updateLoadingStatus(state, action) {
      state.loadingStatus = action.payload;
    },
    searchNAList(state, action) {
      state.naList = action.payload.naList;
      state.totalNAListCount = action.payload.count;
    },
    // Start - Grid Columns Setting Actions
    updateUserColumnSettings(state, action) {
      state.mergedColumns = action.payload;
      state.visibleGridColumns = getVisibleColumns(action.payload);
    },

    updateLoadingColumnSettingStatus(state, action) {
      state.loadingGridColumnStatus = action.payload;
    },

    updateVisibleGridColumns(state, action) {
      state.visibleGridColumns = action.payload;
    },
    updateMergedGridColumns(state, action) {
      state.mergedColumns = action.payload;
    },
    // End - Grid Columns Setting

    updateSavedFilterList(state, action) {
      state.isSavedFilterLoaded = true;
      state.savedFilterList = action.payload;
    },

    applySavedFilter(state, action) {
      applySaveFilterHandler(state, action);
    },

    updatingSorting(state, action) {
      const { sortBy, sortOrder } = action.payload;
      state.tableInfo['sortColumn'] = sortBy;
      state.tableInfo['sortDirection'] = sortOrder;
    },
  },
});

export const tableInfoFields = {
  page: 'page',
  pageSize: 'pageSize',
  sortColumn: 'sortColumn',
  sortDirection: 'sortDirection',
};

export const naListSearchActions = naListSearchSlice.actions;
export default naListSearchSlice.reducer;
