import React, { useState, createContext, useContext } from 'react';
import ConfirmationDialog from './ConfirmationDialog';

export const DIALOG_RESPONSE = {
  YES: 'YES',
  NO: 'NO'
};

export const MODAL_TYPES = {
  CONFIRM: 'CONFIRM',
};

const initalState = {
  showModal: () => {},
  hideModal: () => {},
  store: {}
};

const GlobalDialogContext = createContext(initalState);
export const useGlobalDialogContext = () => useContext(GlobalDialogContext);

export const GlobalModal = ({ children }) => {
  const [store, setStore] = useState();
  const { modalType, modalProps } = store || {};
  // modalType is responsible for showing the dialog
  const showModal = (mType, mProps = {}) => {
    setStore({
      ...store,
      modalType: mType,
      modalProps: mProps
    });
  };

  const hideModal = () => {
    setStore({
      ...store,
      modalType: null,
      modalProps: {}
    });
  };

  const renderComponent = () => {
    if (!modalType) {
      return null;
    }
    return <ConfirmationDialog isOpen={!!modalType} {...modalProps} />;
  };

  return (
    <GlobalDialogContext.Provider value={{ store, showModal, hideModal }}>
      {renderComponent()}
      {children}
    </GlobalDialogContext.Provider>
  );
};
