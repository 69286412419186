import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slice/searchPageSlice';

export const filterFields = {
  Name: 'Name',
  Category: 'Category',
  Type: 'Type',
  BusinessLine: 'BusinessLine',
  Active: 'Active',
};

const initialFilterState = {
  Name: '',
  Category: null,
  Type: null,
  BusinessLine: null,
  Active: true,
};

const initialPageState = {
  page: 0,
  pageSize: 100,
  sortColumn: 'Name',
  sortDirection: SortDirection.Asc,
};

export const sliceName = 'subjectMatterExpertisSearch';

const subjectMatterExpertiseSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState,
  initialPageState
});

export const subjectMatterExpertiseActions = subjectMatterExpertiseSlice.actions;
export default subjectMatterExpertiseSlice.reducer;
