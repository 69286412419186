import { useTheme } from '@mui/material';
import { Box } from '@mui/system';

const Logo = ({ collapsed, width = 122.4, height = 46.35, scale = 1 }) => {
  const theme = useTheme();

  const logoIcon = (
    <g
      transform="translate(0.000000,103.000000) scale(0.100000,-0.100000)"
      fill={theme.palette.primary.main} stroke="none"
    >
      <path
        fill={theme.palette.text.secondary} d="M553 903 c-7 -3 -13 -15 -13 -28 0 -59 -97 -166 -131 -145 -18 12
-39 1 -39 -20 0 -10 -7 -23 -15 -30 -12 -10 -20 -8 -42 10 -15 12 -30 31 -33
43 -4 15 -14 22 -30 22 -21 0 -25 -5 -25 -30 0 -42 -15 -95 -26 -95 -5 0 -14
12 -19 26 -13 33 -45 29 -45 -6 0 -21 4 -25 27 -22 14 1 29 -1 32 -6 8 -13
-33 -114 -55 -135 -19 -18 -17 -42 5 -50 23 -9 39 9 32 37 -3 14 0 42 8 63 8
21 17 47 21 58 7 19 7 19 16 -3 11 -29 52 -27 57 3 2 11 15 29 29 39 30 22 56
12 61 -23 2 -12 11 -25 19 -28 19 -7 44 23 36 42 -5 14 48 100 75 123 9 6 33
12 54 12 34 0 38 3 38 25 0 32 -32 34 -53 3 -8 -13 -18 -20 -22 -16 -11 10 38
78 58 78 11 0 17 8 17 24 0 25 -15 36 -37 29z m-116 -215 c-25 -43 -50 -56
-76 -39 -19 12 -5 31 23 31 14 0 30 10 40 25 28 43 39 27 13 -17z m-137 -3
l23 -24 -24 -20 c-25 -21 -79 -28 -79 -11 0 24 30 80 43 80 7 0 24 -11 37 -25z"
      />
      <path
        fill={theme.palette.primary.main} d="M10 940 l0 -80 35 0 c35 0 35 0 35 45 l0 45 45 0 c45 0 45 0 45 35
l0 35 -80 0 -80 0 0 -80z"
      />
      <path
        fill={theme.palette.primary.main} d="M550 985 c0 -35 0 -35 45 -35 l45 0 0 -45 c0 -45 0 -45 35 -45 l35 0
0 80 0 80 -80 0 -80 0 0 -35z"
      />
      <path
        fill={theme.palette.primary.main} d="M10 400 l0 -80 80 0 80 0 0 35 c0 35 0 35 -45 35 l-45 0 0 45 c0 45
0 45 -35 45 l-35 0 0 -80z"
      />
      <path
        fill={theme.palette.primary.main} d="M640 435 l0 -45 -45 0 c-45 0 -45 0 -45 -35 l0 -35 80 0 80 0 0 80 0
80 -35 0 c-35 0 -35 0 -35 -45z"
      />
    </g>
  );

  return (
    <Box>
      <svg
        version="1.0" xmlns="http://www.w3.org/2000/svg"
        width={`${width * scale}pt`} height={`${height * scale}pt`}
        viewBox="0 0 272.000000 103.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        {collapsed
          ? logoIcon
          : (
            <>
              {logoIcon}
              <text x="80" y="58" fill={theme.palette.primary.main} fontSize={65} fontWeight="bold">CPMS</text>
            </>
          )}
      </svg>

    </Box>
  );
};

export default Logo;
