import useSWR from 'swr';
import UserFavoriteApi from '../services/api/UserFavoriteApi';
// import useAuth from './useAuth';

export const getFavItemSettingSWRKey = '/api/fav/default';
export default function useUserFavoriteSettings() {
  // const { username } = useAuth();

  const fetcher = () => UserFavoriteApi.searchFavorites('Default');

  const { data, error } = useSWR(getFavItemSettingSWRKey, fetcher, {
    revalidateOnFocus: false,
    // revalidateOnMount: false,
    revalidateOnReconnect: false,
    // refreshWhenOffline: false,
    // refreshWhenHidden: false,
    // refreshInterval: 0,
  });

  return {
    favoriteItems: data,
    isLoading: !error && !data,
    isError: error,
  };
}
