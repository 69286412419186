import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { AppBar, Box, Button, IconButton, Toolbar, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuIcon from '../../../asset/icons/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import SearchIcon from '@mui/icons-material/Search';
import AccountPopover from '../../Popovers/AccountPopover/AccountPopover';
// import ContentSearch from "./ContentSearch";
import ThemeIcon from '../ThemeIcon';
import LanguagePopover from '../../Popovers/LanguagePopover/LanguagePopover';
import FavouritePopover from '../FavouritePopover/FavouritePopover';
import SearchDialog from './SearchDialog';
import IF from '../../ui/IF';

export const openDrawerWidth = 270;
export const closeDrawerWidth = 80;
export const navBarHeight = 64;
export const drawerWidth = 270;

const NavbarRoot = styled(AppBar)(({ theme, open }) => ({
  ...(theme.palette.mode === 'light' && {
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper,
    // boxShadow: "none",
    // boxShadow: theme.shadows,
    boxShadow: 'rgba(37, 74, 135, 0.13) 0px 0px 28px 0px',

    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    // borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
  }),
  backgroundColor: theme.palette.background.paper,
  boxShadow: open ? 'rgba(37, 74, 135, 0.13) 0px 0px 28px 0px' : 'none',
  zIndex: theme.zIndex.drawer,
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    marginLeft: open ? openDrawerWidth : closeDrawerWidth,
    width: `calc(100% - ${open ? openDrawerWidth : closeDrawerWidth}px)`, // Adjust width based on sidebar open/close
  },
  // In mobile mode, when the sidebar is closed, set width to 100%
  [theme.breakpoints.down('lg')]: {
    width: open ? `calc(100% - ${openDrawerWidth}px)` : '100%',
  },
}));

const showSearchDialog = false;

const Navbar = (props) => {
  const { onSidebarMobileOpen, ...other } = props;
  const { open } = props;
  const theme = useTheme();
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = useState(false);

  const menuBarIcon = (
    <IconButton color="default" onClick={onSidebarMobileOpen}>
      {open && <MenuOpenIcon sx={{ fontSize: 34 }} />}
      {!open && <MenuIcon sx={{ fontSize: 32 }} />}
      {/* <MenuIcon fontSize="medium" /> */}
    </IconButton>
  );

  const toggleSearchDialog = () => {
    setOpenDialog((prevVal) => !prevVal);
  };

  return (
    <>
      <NavbarRoot {...other}>
        <Toolbar sx={{ minHeight: navBarHeight }}>
          {menuBarIcon}
          <Box
            sx={{
              flexGrow: 1,
              ml: 2,
            }}
          />

          <IF condition={showSearchDialog}>
            <Button
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '4px 8px',
                backgroundColor: 'background.paper',
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: '10px',
              }}
              startIcon={<SearchIcon />}
              onClick={toggleSearchDialog}
            >
              <Typography color="textPrimary" mx={1}>
                {`${t('Label_Search')}...`}
              </Typography>
              <Box
                sx={{
                  fontSize: '0.75rem',
                  fontWeight: 700,
                  lineHeight: '20px',
                  marginLeft: '8px',
                  border: `1px solid ${theme.palette.divider}`,
                  backgroundColor: 'background.default',
                  padding: '0px 4px',
                  borderRadius: '7px'
                }}
              >
                <Typography variant="button" color="textPrimary" mx={0.25} p={0}>
                  Ctrl+K
                </Typography>

              </Box>
            </Button>
          </IF>

          <LanguagePopover />
          <Box sx={{ ml: 1 }}>
            <FavouritePopover />
          </Box>
          {/* <Box sx={{ ml: 1 }}>
          <ContentSearch />
        </Box> */}

          <Box sx={{ ml: 0.5 }}>
            <ThemeIcon />
          </Box>

          <Box sx={{ ml: 2 }}>
            <AccountPopover />
          </Box>
        </Toolbar>
      </NavbarRoot>
      <IF condition={openDialog}>
        <SearchDialog
          toggleDrawer={toggleSearchDialog}
        />
      </IF>
    </>
  );
};

Navbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default Navbar;
