import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slice/searchPageSlice';

export const filterFields = {
  Location: 'Location',
  Program: 'ProgramName',
  SampleName: 'SampleName'
};

const initialFilterState = {
  Location: null,
  ProgramName: null,
  SampleName: null || ''
};

const initialPageState = {
  page: 0,
  pageSize: 100,
  // sortColumn: 'LabName',
  sortColumn: 'LocationName',
  sortDirection: SortDirection.Asc,
};

export const sliceName = 'labSummarySearch';
const labSummarySearchSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState,
  initialPageState,
});

export const labSummarySearchActions = labSummarySearchSlice.actions;
export default labSummarySearchSlice.reducer;
