import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slice/searchPageSlice';

export const filterFields = {
  Active: 'Active',
  ListName: 'ListName',
};

const initialFilterState = {
  ListName: '',
  Active: true,
};

const initialPageState = {
  page: 0,
  pageSize: 100,
  sortColumn: 'ListName',
  sortDirection: SortDirection.Asc,
};

export const sliceName = 'listSearch';
const listSearchSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState,
  initialPageState
});

export const listSearchActions = listSearchSlice.actions;
export default listSearchSlice.reducer;
