/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import { createRoot } from 'react-dom/client';
import 'nprogress/nprogress.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';
import { StyledEngineProvider } from '@mui/styled-engine';
import { SettingsProvider } from './contexts/SettingsContext';
import { Provider as ReduxProvider } from 'react-redux';
import store from './store/index';
// import { AuthProvider } from './contexts/JWTContext';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'react-data-grid/lib/styles.css';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<React.StrictMode>
  <HelmetProvider>
    <ReduxProvider store={store}>
      <StyledEngineProvider injectFirst>
        <SettingsProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <App />
            {/* <AuthProvider>
              <App />
            </AuthProvider> */}
          </LocalizationProvider>
        </SettingsProvider>
      </StyledEngineProvider>
    </ReduxProvider>
  </HelmetProvider>
</React.StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
