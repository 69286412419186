export const SortDirection = {
  Asc: 'asc',
  Desc: 'desc',

  combineSortBy: (col, direction) => {
    if (direction === SortDirection.Asc) {
      return col;
    }

    return `${col}Descending`;
  },
};

export const DefaultPageSizeOptions = [10, 20, 50];
