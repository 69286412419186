import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slice/searchPageSlice';

export const filterFields = {
  GroupName: 'GroupName',
  Type: 'Type',
  Region: 'Region',
  Location: 'Location',
  User: 'User',
  BusinessLine: 'BusinessLine',
  Active: 'Active',
};

const initialFilterState = {
  GroupName: '',
  Type: null,
  Region: null,
  Location: null,
  User: null,
  BusinessLine: null,
  Active: true,
};

const initialPageState = {
  page: 0,
  pageSize: 100,
  sortColumn: 'GroupName',
  sortDirection: SortDirection.Asc,
};

export const sliceName = 'groupsSearch';

const groupsSearchSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState,
  initialPageState
});

export const groupsActions = groupsSearchSlice.actions;
export default groupsSearchSlice.reducer;
