import { createSlice } from '@reduxjs/toolkit';
import { getVisibleColumns } from '../../../../services/ObjectConfiguration/ObjectConfiguration.service';
import { LoadingStatus, SortDirection } from '../../../../utils/constants';

export const filterFields = {
  TestMethod: 'TestMethod',
  Parameter: 'Parameter',
  LabName: 'LabName',
  Programs: 'Programs',
  ProgramProducts: 'ProgramProducts',
  DateFrom: 'DateFrom',
  DateTo: 'DateTo',
  Score: 'Score'
};

const applySaveFilterHandler = (state, action) => {
  const fields = action.payload;
  if (!Array.isArray(fields) || fields.length === 0) {
    return;
  }
  const fieldObj = {};
  fields.forEach(({ Field, Value, Text }) => {
    fieldObj[Field] = Value;
    if (Text) {
      fieldObj[`${Field}_Text`] = Text;
    }
  });

  Object.values(filterFields)?.forEach((fieldName) => {
    const fieldValue = fieldObj[fieldName];
    state.filter[fieldName] = fieldValue || '';
  });
};

const initialFilterState = {
  TestMethod: [],
  Parameter: [],
  Lab: [],
  Programs: [],
  ProgramProducts: [],
  DateFrom: '',
  DateTo: '',
};

const initialPageState = {
  page: 0,
  pageSize: 100,
  // sortColumn: 'LabName',
  sortColumn: 'LocationName',
  sortDirection: SortDirection.Asc,
};

const initialState = {
  filter: initialFilterState,
  tableInfo: initialPageState,
  labParameters: [],
  meanParameters: [],
  totalRecordCount: 0,
  filterData: [],
  loadingStatus: LoadingStatus.Idle, // idle, pending, success, failed
  page: 1,
  sort: null,
  isFilterVisible: true,
  visibleGridColumns: [],
  mergedColumns: [],
  loadingGridColumnStatus: LoadingStatus.Idle,
  isSavedFilterLoaded: false,
  savedFilterList: [],
  loadingFilterSettingStatus: LoadingStatus.Idle,
  totalRecords: [],
};

const trendingGraphSearchSlice = createSlice({
  name: 'trendingGraphSearch',
  initialState,
  reducers: {
    showHideFilter(state, action) {
      state.isFilterVisible = action.payload;
    },
    updateFilterValue(state, action) {
      state.filter[action.payload.propName] = action.payload.propValue;
    },
    resetSearchFilter(state) {
      state.filter = { ...initialFilterState };
    },
    resetSearchFiltersAndPagination(state, action) {
      state.filter = { ...initialFilterState, ...action.payload };
      state.tableInfo = { ...initialPageState };
    },
    updatePageInfoField(state, action) {
      const { propName, propValue } = action.payload;
      state.tableInfo[propName] = propValue;
    },
    setPage(state, action) {
      state.tableInfo.page = action.payload;
    },
    updateLoadingStatus(state, action) {
      state.loadingStatus = action.payload;
    },
    searchTrendingReport(state, action) {
      state.labParameters = action.payload.labParameters;
      state.meanParameters = action.payload.meanParameters;
    },

    searchFilter(state, action) {
      state.filterData = action.payload;
    },

    updateFilterShowHideSettings(state, action) {
      state.filterData = action.payload;
    },
    // Start - Grid Columns Setting Actions
    updateUserColumnSettings(state, action) {
      state.mergedColumns = action.payload;
      state.visibleGridColumns = getVisibleColumns(action.payload);
    },

    updateLoadingColumnSettingStatus(state, action) {
      state.loadingGridColumnStatus = action.payload;
    },

    updateVisibleGridColumns(state, action) {
      state.visibleGridColumns = action.payload;
    },
    updateMergedGridColumns(state, action) {
      state.mergedColumns = action.payload;
    },
    // End - Grid Columns Setting

    updateSavedFilterList(state, action) {
      state.isSavedFilterLoaded = true;
      state.savedFilterList = action.payload;
    },

    applySavedFilter(state, action) {
      applySaveFilterHandler(state, action);
    },

    updateLoadingFilterSettingStatus(state, action) {
      state.loadingFilterSettingStatus = action.payload;
    },
    setAllRecords(state, action) {
      state.totalRecords = action.payload;
    },
    updatingSorting(state, action) {
      const { sortBy, sortOrder } = action.payload;
      state.tableInfo['sortColumn'] = sortBy;
      state.tableInfo['sortDirection'] = sortOrder;
    },
  },
});

export const tableInfoFields = {
  page: 'page',
  pageSize: 'pageSize',
  sortColumn: 'sortColumn',
  sortDirection: 'sortDirection',
};

export const trendingGraphSearchActions = trendingGraphSearchSlice.actions;
export default trendingGraphSearchSlice.reducer;
