import { createSlice } from '@reduxjs/toolkit';
import { getVisibleColumns } from '../../../../services/ObjectConfiguration/ObjectConfiguration.service';
import { LoadingStatus, SortDirection } from '../../../../utils/constants';

export const filterFields = {
  belongsTo: 'belongsTo',
  cycle: 'cycle',
  naList: 'naList',
  program: 'program',
  organization: 'organization',
};

const applySaveFilterHandler = (state, action) => {
  const fields = action.payload;
  if (!Array.isArray(fields) || fields.length === 0) {
    return;
  }

  const fieldObj = {};
  fields.forEach(({ Field, Value, Text }) => {
    fieldObj[Field] = Value;
    if (Text) {
      fieldObj[`${Field}_Text`] = Text;
    }
  });

  [
    filterFields.belongsTo,
    filterFields.cycle,
    filterFields.naList,
    filterFields.program,
    filterFields.organization,
  ].forEach((fieldName) => {
    const fieldValue = fieldObj[fieldName];
    state.filter[fieldName] = fieldValue || '';
  });
};

const initialFilterState = {
  belongsTo: { id: 1, text: 'Cycle' },
  cycle: null,
  naList: null,
  program: null,
  organization: null,
};

const initialPageState = {
  page: 0,
  pageSize: 100,
  sortColumn: 'CreatedOn',
  sortDirection: SortDirection.Desc,
};

const initialState = {
  filter: initialFilterState,
  tableInfo: initialPageState,

  cycles: [],
  totalCycleCount: 0,
  loadingStatus: LoadingStatus.Idle, // idle, pending, success, failed

  page: 1,
  sort: null,

  isFilterVisible: true,

  visibleGridColumns: [],
  mergedColumns: [],
  loadingGridColumnStatus: LoadingStatus.Idle,

  isSavedFilterLoaded: false,
  savedFilterList: [],
};

const auditSearchSlice = createSlice({
  name: 'auditSearch',
  initialState,
  reducers: {
    showHideFilter(state, action) {
      state.isFilterVisible = action.payload;
    },
    updateFilterValue(state, action) {
      const { propName, propValue } = action.payload;
      state.filter[propName] = propValue;
    },
    resetSearchFilter(state) {
      state.filter = { ...initialFilterState };
    },
    resetSearchFiltersAndPagination(state, action) {
      state.filter = { ...initialFilterState, ...action.payload };
      state.tableInfo = { ...initialPageState };
    },
    updatePageInfoField(state, action) {
      const { propName, propValue } = action.payload;
      state.tableInfo[propName] = propValue;
    },
    setPage(state, action) {
      state.tableInfo.page = action.payload;
    },
    updateLoadingStatus(state, action) {
      state.loadingStatus = action.payload;
    },
    searchAudit(state, action) {
      state.cycles = action.payload.cycles;
      state.totalCycleCount = action.payload.totalCycleCount;
    },
    // Start - Grid Columns Setting Actions
    updateUserColumnSettings(state, action) {
      state.mergedColumns = action.payload;
      state.visibleGridColumns = getVisibleColumns(action.payload);
    },

    updateLoadingColumnSettingStatus(state, action) {
      state.loadingGridColumnStatus = action.payload;
    },

    updateVisibleGridColumns(state, action) {
      state.visibleGridColumns = action.payload;
    },
    updateMergedGridColumns(state, action) {
      state.mergedColumns = action.payload;
    },
    // End - Grid Columns Setting

    updateSavedFilterList(state, action) {
      state.isSavedFilterLoaded = true;
      state.savedFilterList = action.payload;
    },

    applySavedFilter(state, action) {
      applySaveFilterHandler(state, action);
    },
  },
});

export const tableInfoFields = {
  page: 'page',
  pageSize: 'pageSize',
  sortColumn: 'sortColumn',
  sortDirection: 'sortDirection',
};

export const auditSearchActions = auditSearchSlice.actions;
export default auditSearchSlice.reducer;
