import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slice/searchPageSlice';

export const filterFields = {
  LocationAlias: 'LocationAlias',
  LocationName: 'LocationName',
  LocationAbbreviation: 'LocationAbbreviation',
  LocationType: 'LocationType',
  AccreditationBody: 'AccreditationBody',
  Region: 'Region',
  Zone: 'Zone',
  Country: 'Country',
  Active: 'Active',
};

const initialFilterState = {
  LocationAlias: '',
  LocationName: '',
  LocationAbbreviation: '',
  Region: null,
  LocationType: [],
  AccreditationBody: [],
  Zone: null,
  Country: null,
  Active: true,
};

const initialPageState = {
  page: 0,
  pageSize: 100,
  sortColumn: 'LocationName',
  sortDirection: SortDirection.Asc,
};

export const sliceName = 'locationSearch';
const locationSearchSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState,
  initialPageState,
});

export const locationSearchActions = locationSearchSlice.actions;
export default locationSearchSlice.reducer;
