import { combineReducers } from '@reduxjs/toolkit';
import globalDataReducer from '../slices/globalData-slice';
import authRedcer from '../slices/authSlice';
import programSearchReducer from '../../pages/Program/store/slices/programSearchSlice';
import methodSearchSlice from '../../pages/MethodSearch/store/slices/methodSearchSlice';
import organizationSearchReducer from '../../pages/Organization/store/slices/organizationSearchSlice';
import naListSearchReducer from '../../pages/NAList/store/slices/NAListSearchSlice';
import listSearchReducer from '../../pages/List/store/slices/listSearchSlice';
import enrollmentSearchReducer from '../../pages/Enrollment/store/slices/enrollmentSearchSlice';
import cycleSearchReducer from '../../pages/CycleSearch/store/slices/cycleSearchSlice';
import auditSearchReducer from '../../pages/AuditSearch/store/slices/auditSearchSlice';
import actionsSearchReducer from '../../pages/ActionSearch/store/slices/actionsSearchSlice';
import emailsSearchReducer from '../../pages/Emails/store/slices/emailSearchSlice';
import productsSearchReducer from '../../pages/Products/store/slices/productsSearchSlice';
import locationSearchReducer from '../../pages/Location/store/slices/locationSearchSlice';
import labSummarySearchReducer from '../../pages/LabSummaryReport/store/slices/labSummarySearchSlice';
import outstandingCARsSearchReducer from '../../pages/OutstandingCARs/store/slices/outstandingCARsSearchSlice';
import trendingGraphSearchReducer from '../../pages/TrendingGraphs/store/slices/trendingGraphSearchSlice';
import countriesSearchReducer from '../../pages/Countries/store/slices/countriesSearchSlice';
import unitSearchReducer from '../../pages/Unit/store/slices/unitSearchSlice';
import ignoreListSearchReducer from '../../pages/IgnoreList/store/slices/ignoreListSearchSlice';
import regionSearchReducer from '../../pages/Region/store/slices/regionSearchSlice';
import contactsSearchReducer from '../../pages/Contacts/store/slices/contactsSearchSlice';
import roleSearchReducer from '../../pages/Role/store/slices/roleSearchSlice';
import subjectMatterExpertisReducer from '../../pages/SubjectMatterExpertise/store/slices/SubjectMatterExpertisSlice';
import userSearchReducer from '../../pages/User/store/slices/userSearchSlice';
import announcementsSearchSlice from '../../pages/Announcements/store/slices/AnnouncementsSlice';
import groupsSearchSlice from '../../pages/GroupsSearch/store/slices/GroupsSlice';
import accreditationBodiesSlice from '../../pages/AccreditationBodies/store/slices/AccreditationBodiesSlice';
import myActionSearchReducer from '../../pages/MyActions/store/slices/myActionsSlice';
import myAnnouncementsSearchReducer from '../../pages/MyAnnouncements/store/slices/MyAnnouncementsSlice';
import methodEquivalenceSearchReducer from '../../pages/MethodEquivalence/store/slices/methodEquivalenceSlice';
import companySummaryReportReducer from '../../pages/ComapnySummaryReport/store/slices/companySummaryReportSlice';
import dataExportReducer from '../../pages/DataExport/store/slices/dataExportSlice';
import reportGradingReducer from '../../pages/ReportGrading/store/slices/reportGradingSlice';
import zCarsPercentageReportReducer from '../../pages/ZCARReport/store/slices/zCarPercentageReportSlice';
import assetSearchReducer from '../../pages/Asset/store/slices/AssetSearchSlice';

const rootReducer = combineReducers({
  globalData: globalDataReducer,
  auth: authRedcer,
  programSearch: programSearchReducer,
  methodSearch: methodSearchSlice,
  organizationSearch: organizationSearchReducer,
  naListSearch: naListSearchReducer,
  listSearch: listSearchReducer,
  enrollmentSearch: enrollmentSearchReducer,
  cycleSearch: cycleSearchReducer,
  auditSearch: auditSearchReducer,
  actionsSearch: actionsSearchReducer,
  emailsSearch: emailsSearchReducer,
  productsSearch: productsSearchReducer,
  locationSearch: locationSearchReducer,
  labSummarySearch: labSummarySearchReducer,
  outstandingCARsSearch: outstandingCARsSearchReducer,
  trendingSearch: trendingGraphSearchReducer,
  countriesSearch: countriesSearchReducer,
  unitSearch: unitSearchReducer,
  ignoreListSearch: ignoreListSearchReducer,
  regionSearch: regionSearchReducer,
  contactsSearch: contactsSearchReducer,
  roleSearch: roleSearchReducer,
  subjectMatterExpertisSearch: subjectMatterExpertisReducer,
  userSearch: userSearchReducer,
  announcementsSearch: announcementsSearchSlice,
  groupsSearch: groupsSearchSlice,
  accreditationBodiesSearch: accreditationBodiesSlice,
  myActions: myActionSearchReducer,
  myAnnouncementsSearch: myAnnouncementsSearchReducer,
  methodEquivalenceSearch: methodEquivalenceSearchReducer,
  companySummaryReport: companySummaryReportReducer,
  dataExportSearch: dataExportReducer,
  reportGrading: reportGradingReducer,
  zCarPercentageReport: zCarsPercentageReportReducer,
  assetSearch: assetSearchReducer,
});
export default rootReducer;
