import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Tabs,
  Tab,
  Popover,
  ButtonBase,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
  Typography,
  Tooltip,
  Skeleton,
} from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useSWRConfig } from 'swr';
import useAuth from '../../../hooks/useAuth';
import useUserFavoriteSettings, {
  getFavItemSettingSWRKey,
} from '../../../hooks/useUserFavoriteSettings';
import UserFavoriteApi from '../../../services/api/UserFavoriteApi';

const compareBelongsTo = (a, b) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

const FavouritePopover = () => {
  const anchorRef = useRef(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { username } = useAuth();
  const [selectedTabName, setSelectedTabName] = useState('0');
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const { mutate } = useSWRConfig();
  const { favoriteItems, isLoading, isError } = useUserFavoriteSettings();
  // const { data: favoriteItems, error: loadingError } = useSWR(
  //   isOpen ? [getFavItemSettingSWRKey, { type: 'Default', username }] : null,
  //   getUserFavoriteSettingsFetcher
  // );

  // -- Event Handler(s) --
  const handleChange = (event, newValue) => {
    setSelectedTabName(newValue);
  };

  const handleOpen = () => {
    setOpen(true);
    setIsOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsOpen(false);
  };

  useEffect(() => {
    if (Array.isArray(favoriteItems)) {
      if (+selectedTabName > new Set(favoriteItems.map((x) => x.BelongsTo)).size - 1) {
        setSelectedTabName('0');
      }
    }
  }, [favoriteItems, selectedTabName]);

  const onDeleteFavouriteItemHandler = async (event, userFavoriteSettingId) => {
    event.preventDefault();
    event.stopPropagation();

    if (!userFavoriteSettingId) {
      return;
    }

    try {
      const newJobFavouritesList = favoriteItems.filter(
        (x) => x.UserFavoriteSettingID !== userFavoriteSettingId
      );

      mutate(getFavItemSettingSWRKey, [...newJobFavouritesList], false);
      await UserFavoriteApi.deleteUserFavorites(userFavoriteSettingId);
      mutate(getFavItemSettingSWRKey);
    } catch (error) {
      console.log(error);
      mutate([getFavItemSettingSWRKey, { type: 'Default', username }]);
    }
  };

  const onNavigateToFavouriteItemDetailHandler = (groupName, recordId) => {
    if (!recordId) {
      return;
    }

    if (groupName === 'Cycle') {
      navigate(`/cycles/${recordId}/edit`);
    } else if (groupName === 'Program') {
      navigate(`/Program/${recordId}/edit`);
    }

    handleClose();
  };

  // -- Event Handler(s) --

  const emptyListContent = (
    <Box sx={{ textAlign: 'center' }}>
      <Typography variant="subtitle1">{t('Error_NoItemsFound')}</Typography>
    </Box>
  );

  let popoverContent = null;
  if (isError) {
    popoverContent = <h1>Opps...</h1>;
  } else if (isLoading) {
    popoverContent = (
      <Box padding={1}>
        <Skeleton variant="rectangular" height={20} />
        <Skeleton variant="rectangular" height={20} sx={{ my: 1 }} />
        <Skeleton variant="rectangular" height={20} sx={{ my: 1 }} />
        <Skeleton variant="rectangular" height={20} />
      </Box>
    );
  } else {
    const groupItemNames = favoriteItems.length > 0 ? [...new Set(favoriteItems.map((x) => x.BelongsTo).sort(compareBelongsTo))] : [];

    const tabs = groupItemNames.length > 0 ? (
      <Tabs
        value={selectedTabName}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="scrollable force tabs example"
      >
        {groupItemNames.map((groupName, index) => (
          <Tab key={`FavTab_${groupName}`} label={t(groupName)} value={`${index}`} />
        ))}
      </Tabs>
    ) : (
      emptyListContent
    );

    const tabPanelsContent = groupItemNames.map((groupName, index) => {
      const filterFavItems = favoriteItems.filter((x) => x.BelongsTo === groupName);
      return (
        <TabPanel key={`Fav-tabPanel-${groupName}`} value={`${index}`} sx={{ py: 0, px: 0.8 }}>
          <List dense sx={{ overflow: 'auto', height: 220 }}>
            {filterFavItems && !filterFavItems.length && emptyListContent}

            {filterFavItems
              && filterFavItems.length > 0
              && filterFavItems.map(({ UserFavoriteSettingID, RecordID, Text }) => (
                <ListItem
                  key={`FavItem-${groupName}-${RecordID}`}
                  disablePadding
                  onClick={() => onNavigateToFavouriteItemDetailHandler(groupName, RecordID)}
                  secondaryAction={
                    <Box sx={{ display: 'flex' }}>
                      <Box sx={{ position: 'relative' }}>
                        <Tooltip title={t('Btn_Delete')}>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={(event) => onDeleteFavouriteItemHandler(event, UserFavoriteSettingID)}
                          >
                            <DeleteOutlineIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  }
                >
                  <ListItemButton>
                    <ListItemText primary={`${Text}`} />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </TabPanel>
      );
    });

    popoverContent = (
      <TabContext value={selectedTabName}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>{tabs}</Box>
        {tabPanelsContent}
      </TabContext>
    );
  }

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        {isOpen && <BookmarkIcon size="small" color="primary" />}
        {!isOpen && <BookmarkBorderIcon size="small" color="action" />}
      </Box>

      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        // keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 280 },
        }}
      >
        <Box sx={{ width: '100%', typography: 'body1' }}>{popoverContent}</Box>
      </Popover>
    </>
  );
};

export default FavouritePopover;
