import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slice/searchPageSlice';

export const filterFields = {
  CycleName: 'CycleName',
  PTPOrganization: 'PTPOrganization',
  BusinessLine: 'BusinessLine',
  ProgramName: 'ProgramName',
  ProgramProducts: 'ProgramProducts',
  CycleStatus: 'CycleStatus',
  CreatedFrom: 'CreatedFrom',
  CreatedTo: 'CeatedTo',
};

export const sliceName = 'cycleSearch';
const cycleSearchSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState: {
    CycleName: '',
    PTPOrganization: null,
    BusinessLine: null,
    ProgramName: null,
    ProgramProducts: '',
    CycleStatus: [],
    CreatedFrom: '',
    CreatedTo: '',
  },
  initialPageState: {
    page: 0,
    pageSize: 50,
    sortColumn: 'CreatedOn',
    sortDirection: SortDirection.Desc
  },
});

export const cycleSearchActions = cycleSearchSlice.actions;
export default cycleSearchSlice.reducer;
