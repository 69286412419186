import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Dialog, DialogContent, DialogTitle, Divider, IconButton, Stack, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

const listItems = ['Dashboard', 'Cycles', 'Enrollments', 'Methods', 'Program', 'NAList'];

const SearchDialog = ({ toggleDrawer }) => {
  const { t } = useTranslation();
  const [list, setList] = useState(listItems);

  const handleInputChange = (searchedVal) => {
    const filteredData = listItems?.filter(
      (item) => (item.trim().toLowerCase().includes(searchedVal.toLowerCase()))
    );
    setList(filteredData);
  };

  return (
    <Dialog
      open
      onClose={toggleDrawer}
      fullWidth
      maxWidth="sm"
      sx={{ '& .MuiDialog-paper': { height: 350 } }}
    >
      <DialogTitle>
        <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
          <SearchIcon color="primary" />
          <TextField
            id="search-bar"
            fullWidth
            autoFocus
            variant="standard"
            placeholder={`${t('Label_Search')}...`}
            InputProps={{
              disableUnderline: true,
            }}
            onChange={(e) => {
              handleInputChange(e.target.value);
            }}
          />
        </Stack>
        <IconButton
          aria-label="close"
          onClick={toggleDrawer}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {list.map((x) => (
          <Box>
            {x}
          </Box>
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default SearchDialog;
