import { createSlice } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../utils/constants';

const initialState = {
  clients: [],
  loadingClientStatus: LoadingStatus.Idle, // idle, pending, success, failed
  objectConfigsById: {},
};

const globalDataSlice = createSlice({
  name: 'globalData',
  initialState,
  reducers: {
    updateLoadingStatus(state, action) {
      state.loadingClientStatus = action.payload;
    },
    updateClients(state, action) {
      state.clients = action.payload;
    },
    updateObjectConifgs(state, action) {
      if (Array.isArray(action.payload)) {
        action.payload.forEach(({ id, ObjectConfigs }) => {
          state.objectConfigsById[id] = ObjectConfigs;
        });
      }
    },
  },
});

export const globalDataActions = globalDataSlice.actions;
export default globalDataSlice.reducer;
