// import jobApi from '../../api/JobApi';
import AuthApi from '../../services/api/AuthApi';
import { authActions } from '../slices/authSlice';
import { parseJwt } from '../../utils/jwt';
import UserApi from '../../services/api/UserApi';

const calculateRemainingTime = (expirationTime) => {
  const currentTime = new Date().getTime();
  const adjExpirationTime = new Date(expirationTime).getTime();
  const remainingDuration = adjExpirationTime - currentTime;
  return remainingDuration || 0;
};

const retrieveStoredToken = () => {
  const storedToken = localStorage.getItem('accessToken');
  const storedExpirationDate = localStorage.getItem('expirationTime');

  const remainingTime = calculateRemainingTime(+storedExpirationDate);

  if (remainingTime <= 10000) {
    // 10 s
    localStorage.removeItem('token');
    localStorage.removeItem('expirationTime');
    return null;
  }

  return {
    token: storedToken,
    duration: remainingTime,
  };
};

const getUserDateTimeFormats = (user) => {
  if (user) {
    return {
      dateFormat: user.DateFormat || 'MM/dd/yyyy',
      dateTimeFormat: user.DateTimeFormat || 'MM/dd/yyyy',
    };
  }
  return {
    dateFormat: 'MM/dd/yyyy',
    dateTimeFormat: 'MM/dd/yyyy',
  };
};

export const initialize = () => async (dispatch) => {
  try {
    const tokenData = retrieveStoredToken();

    if (tokenData && tokenData.token) {
      dispatch(authActions.updateValidatingToken(true));
      const loginUserData = await AuthApi.me(tokenData.token);
      const { dateFormat, dateTimeFormat } = getUserDateTimeFormats(loginUserData.User);
      dispatch(
        authActions.updateLoginUser({
          isAuthenticated: true,
          user: loginUserData.User,
          roleClaims: loginUserData.RoleClaims,
          token: tokenData.token,
          userObjects: loginUserData.UserObjects,
          dateFormat,
          dateTimeFormat,
          tenantList: loginUserData.Tenants,
          permissions: loginUserData.Permissions,
          customPermissions: loginUserData.CustomPermissions,
        })
      );
      dispatch(authActions.updateValidatingToken(false));
    } else {
      dispatch(
        authActions.updateLoginUser({
          isAuthenticated: false,
          user: null,
          roleClaims: null,
        })
      );
    }
  } catch (err) {
    dispatch(
      authActions.updateLoginUser({
        isAuthenticated: false,
        user: null,
        roleClaims: null,
      })
    );

    dispatch(authActions.updateValidatingToken(false));
  }
};

export const login = (data) => async (dispatch) => {
  const { username, password } = data;
  const { accessToken, userData } = await AuthApi.login({
    username,
    password,
  });

  const { exp } = parseJwt(accessToken);

  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('expirationTime', exp * 1000);

  const { dateFormat, dateTimeFormat } = getUserDateTimeFormats(userData.User);

  dispatch(
    authActions.updateLoginUser({
      isAuthenticated: true,
      user: userData.User,
      roleClaims: userData.RoleClaims,
      token: accessToken,
      userObjects: userData.UserObjects,
      dateFormat,
      dateTimeFormat,
      tenantList: userData.Tenants,
      permissions: userData.Permissions,
      customPermissions: userData.CustomPermissions,
    })
  );
};

export const logout = () => async (dispatch) => {
  try {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('expirationTime');

    dispatch(authActions.logout());
    dispatch({ type: 'store/reset' });
  } catch (err) {
    console.log(err);
  }
};

export const startImpersonating = (data) => async (dispatch) => {
  const { UserAccountID, UserID, Username } = data;
  const userData = await UserApi.getUserDataWithRoleByUserID({ userId: UserID });
  const { dateFormat, dateTimeFormat } = getUserDateTimeFormats(userData.User);

  dispatch(
    authActions.startImpersonating({
      UserAccountID,
      UserID,
      Username,
      dateFormat,
      dateTimeFormat,
      user: userData.User,
      roleClaims: userData.RoleClaims,
      userObjects: userData.UserObjects,
    })
  );
};
