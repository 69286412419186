import { createSlice } from '@reduxjs/toolkit';
import { getVisibleColumns } from '../../../../services/ObjectConfiguration/ObjectConfiguration.service';
import { LoadingStatus, SortDirection } from '../../../../utils/constants';

export const filterFields = {
  Year: 'Year',
  Quarter: 'Quarter',
  Products: 'Products',
  BusinessLine: 'BusinessLine',
  Zone: 'Zone',
  Location: 'Location'
};

const applySaveFilterHandler = (state, action) => {
  const fields = action.payload;
  if (!Array.isArray(fields) || fields.length === 0) {
    return;
  }

  const fieldObj = {};
  fields.forEach(({ Field, Value, Text }) => {
    fieldObj[Field] = Value;
    if (Text) {
      fieldObj[`${Field}_Text`] = Text;
    }
  });

  Object.values(filterFields).forEach((fieldName) => {
    const fieldValue = fieldObj[fieldName];
    state.filter[fieldName] = fieldValue || '';
  });
};

const currentYear = new Date().getFullYear();
const currentQuarter = Math.ceil((new Date().getMonth() + 1) / 3);

const initialFilterState = {
  Year: currentYear,
  Quarter: currentQuarter,
  Products: null,
  BusinessLine: null,
  Zone: null,
  Location: null
};

const initialPageState = {
  page: 0,
  pageSize: 100,
  sortColumn: 'AnnouncementNo',
  sortDirection: SortDirection.Asc,
};

const initialState = {
  filter: initialFilterState,
  tableInfo: initialPageState,

  labDataList: [],
  yearQuarters: [],
  totalRecordCount: 0,
  loadingStatus: LoadingStatus.Idle, // idle, pending, success, failed

  page: 1,
  sort: null,

  isFilterVisible: true,

  visibleGridColumns: [],
  mergedColumns: [],
  loadingGridColumnStatus: LoadingStatus.Idle,

  isSavedFilterLoaded: false,
  savedFilterList: [],
};

const reportGradingSearchSlice = createSlice({
  name: 'reportGrading',
  initialState,
  reducers: {
    showHideFilter(state, action) {
      state.isFilterVisible = action.payload;
    },
    updateFilterValue(state, action) {
      const { propName, propValue } = action.payload;
      // console.log('propName', propName, 'propValue', propValue);
      state.filter[propName] = propValue;
    },
    resetSearchFilter(state) {
      state.filter = { ...initialFilterState };
    },
    resetSearchFiltersAndPagination(state, action) {
      state.filter = { ...initialFilterState, ...action.payload };
      state.tableInfo = { ...initialPageState };
    },
    updatePageInfoField(state, action) {
      const { propName, propValue } = action.payload;
      state.tableInfo[propName] = propValue;
    },
    setPage(state, action) {
      state.tableInfo.page = action.payload;
    },
    updateLoadingStatus(state, action) {
      state.loadingStatus = action.payload;
    },
    searchReportGrading(state, action) {
      state.labDataList = action.payload.labDataList;
      state.yearQuarters = action.payload.yearQuarters;
    },
    // Start - Grid Columns Setting Actions
    updateUserColumnSettings(state, action) {
      state.mergedColumns = action.payload;
      state.visibleGridColumns = getVisibleColumns(action.payload);
    },

    updateLoadingColumnSettingStatus(state, action) {
      state.loadingGridColumnStatus = action.payload;
    },

    updateVisibleGridColumns(state, action) {
      state.visibleGridColumns = action.payload;
    },
    updateMergedGridColumns(state, action) {
      state.mergedColumns = action.payload;
    },
    // End - Grid Columns Setting

    updateSavedFilterList(state, action) {
      state.isSavedFilterLoaded = true;
      state.savedFilterList = action.payload;
    },

    applySavedFilter(state, action) {
      applySaveFilterHandler(state, action);
    },
  },
});

export const reportGradingActions = reportGradingSearchSlice.actions;
export default reportGradingSearchSlice.reducer;
