import HomeIcon from '@mui/icons-material/Home';
import useAuth from '../../../hooks/useAuth';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CampaignIcon from '@mui/icons-material/Campaign';
import { PermissionCodes, PermissionRights } from '../../Guards/PermissionGuard/PermissionGuard';

import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import VerifiedUserRoundedIcon from '@mui/icons-material/VerifiedUserRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import SouthAmericaRoundedIcon from '@mui/icons-material/SouthAmericaRounded';
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import BiotechOutlinedIcon from '@mui/icons-material/BiotechOutlined';
import BusinessIcon from '@mui/icons-material/Business';
import TerminalIcon from '@mui/icons-material/Terminal';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import WorkIcon from '@mui/icons-material/Work';
import ContactsIcon from '@mui/icons-material/Contacts';
import BadgeIcon from '@mui/icons-material/Badge';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import RuleFolderIcon from '@mui/icons-material/RuleFolder';
import GroupsIcon from '@mui/icons-material/Groups';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import CategoryIcon from '@mui/icons-material/Category';
import InventoryIcon from '@mui/icons-material/Inventory';
import GradingIcon from '@mui/icons-material/Grading';
import PercentIcon from '@mui/icons-material/Percent';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import BiotechIcon from '@mui/icons-material/Biotech';
import HighlightIcon from '@mui/icons-material/Highlight';
import StoreIcon from '@mui/icons-material/Store';
import MarkEmailReadRoundedIcon from '@mui/icons-material/MarkEmailReadRounded';
import SettingsIcon from '@mui/icons-material/Settings';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import PowerIcon from '@mui/icons-material/Power';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import WebAssetOutlinedIcon from '@mui/icons-material/WebAssetOutlined';

const useNavSections = () => {
  const { t } = useTranslation();
  const sections = useMemo(
    () => [
      {
        title: t('Home'),
        path: '/',
        icon: <HomeIcon fontSize="small" />,
        items: [
        // {
        //   title: 'Overview',
        //   icon: <DashboardIcon fontSize="small" />,
        //   path: '/dashboard',
        //   permission: PermissionCodes.Dashboard,
        // },
          {
            title: 'Dashboard',
            icon: <HomeIcon />,
            path: '/dashboard',
            permission: PermissionCodes.Dashboard,
          },
          {
            title: t('Nav_Menu_Actions'),
            icon: <NotificationImportantIcon />,
            permission: PermissionCodes.Action,
            path: '/actions',
          },
          {
            title: t('Nav_Menu_MyActions'),
            icon: <NotificationsActiveIcon />,
            permission: PermissionCodes.MyActions,
            path: '/myactions',
          },
          { title: 'My Announcements',
            icon: <CampaignIcon />,
            path: '/myannouncements',
            permission: PermissionCodes.MyAnnouncement,
          },
        ],
      },
      {
        title: t('Nav_Menu_Capability'),
        path: '/',
        icon: <PowerIcon fontSize="small" />,
        items: [
          {
            title: 'Locations',
            icon: <LocationOnRoundedIcon />,
            path: '/locations',
            permission: PermissionCodes.Location,
          },
        ],
      },
      {
        title: t('Nav_Menu_Performance'),
        path: '/',
        icon: <PrecisionManufacturingIcon fontSize="small" />,
        items: [
          {
            title: 'Enrollments',
            icon: <AppRegistrationIcon />,
            path: '/enrollments',
            permission: PermissionCodes.Enrollment,
          },
          {
            title: 'NA List',
            icon: <RuleFolderIcon />,
            path: '/naList',
            permission: PermissionCodes.NAList,
          },
          {
            title: 'Cycles',
            icon: <WorkIcon />,
            path: '/cycles',
            permission: PermissionCodes.Cycle,
          },
        ],
      },
      {
        title: t('Nav_Menu_Reports'),
        path: '/',
        icon: <SummarizeIcon fontSize="small" />,
        items: [
          {
            title: t('Nav_Menu_LabSummaryReport'),
            icon: <BiotechIcon />,
            permission: PermissionCodes.LabSummaryReport,
            path: '/reports/locationReport',
          },
          {
            title: t('Title_CompanySummaryReport'),
            icon: <StoreIcon />,
            permission: PermissionCodes.CompanySummaryReport,
            path: '/reports/companySummary',
          },
          {
            path: '/reports/trends',
            title: t('Nav_Menu_Trends'),
            icon: <TrendingUpIcon />,
            permission: PermissionCodes.TrendingGraphReport,
          },
          {
            path: '/reports/zCarPercentageReport',
            title: t('Nav_Menu_ZCarPercentageReport'),
            icon: <PercentIcon />,
            permission: PermissionCodes.ZCarPercentageReport,
          },
          {
            title: t('Title_ReportGrading'),
            icon: <GradingIcon />,
            permission: PermissionCodes.ReportGrading,
            path: '/reports/reportGrading',
          },
          {
            title: t('Nav_Menu_OutstandingCARs'),
            icon: <HighlightIcon />,
            permission: PermissionCodes.OutstandingCARsReport,
            path: '/reports/outstandingCARs'
          },
          {
            title: t('Title_DataExport'),
            icon: <FileDownloadIcon />,
            permission: PermissionCodes.DataExport,
            path: '/reports/dataExport',
          },
        ],
      },
      {
        title: 'Master Data',
        icon: <SettingsIcon fontSize="small" />,
        items: [
          // {
          //   title: 'Audit',
          //   icon: <HomeIcon />,
          //   path: '/audits',
          //   permission: PermissionCodes.Audit,
          // },
          {
            title: 'Organizations',
            icon: <BusinessIcon />,
            path: '/organization',
            permission: PermissionCodes.Organization,
          },
          {
            title: t('Nav_Menu_Program'),
            icon: <TerminalIcon />,
            path: '/program',
            permission: PermissionCodes.Program,
          },
          {
            title: 'Countries',
            icon: <PublicRoundedIcon />,
            path: '/countries',
            permission: PermissionCodes.Country,
          },
          {
            title: 'Regions',
            icon: <SouthAmericaRoundedIcon />,
            path: '/regions',
            permission: PermissionCodes.Region,
          },
          { title: 'Accreditation Bodies',
            icon: <LocationCityIcon />,
            path: '/accreditationBodies',
            permission: PermissionCodes.AccreditationBody,
          },
          {
            title: 'Methods',
            icon: <ScienceOutlinedIcon />,
            path: '/methods',
            permission: PermissionCodes.TestMethod,
          },
          {
            title: 'Method Equivalence',
            icon: <BiotechOutlinedIcon />,
            path: '/methodEquivalence',
            permission: PermissionCodes.MethodEquivalence,
          },
          {
            title: 'Products',
            icon: <InventoryIcon />,
            path: '/products',
            permission: PermissionCodes.Product,
          },
          {
            title: 'Units',
            icon: <CategoryIcon />,
            path: '/units',
            permission: PermissionCodes.Unit,
          },
          {
            title: 'Contacts',
            icon: <ContactsIcon />,
            path: '/contacts',
            permission: PermissionCodes.Contact,
          },
          {
            title: 'Subject Matter Expertise',
            icon: <BadgeIcon />,
            path: '/sme',
            permission: PermissionCodes.SubjectMatterExpertise,
          },
          {
            title: 'List',
            icon: <FormatListBulletedIcon />,
            path: '/list',
            permission: PermissionCodes.List,
          },
          {
            title: 'IgnoreList',
            icon: <FilterAltOffIcon />,
            path: '/ignoreList',
            permission: PermissionCodes.IgnoreList,
          },
          { title: 'Announcements',
            icon: <CampaignIcon />,
            path: '/announcements',
            permission: PermissionCodes.Announcement,
          },
          { title: 'Groups',
            icon: <GroupsIcon />,
            path: '/groups',
            permission: PermissionCodes.Group,
          },
          {
            title: t('Nav_Menu_Sent_Emails'),
            icon: <MarkEmailReadRoundedIcon />,
            path: '/sentEmails',
            permission: PermissionCodes.Email,
          },
          {
            title: 'Assets',
            icon: <WebAssetOutlinedIcon />,
            path: '/assets',
            permission: PermissionCodes.Asset,
          },
        ],
      },
      {
        title: 'Administrator',
        icon: <ManageAccountsIcon fontSize="small" />,
        items: [
          {
            title: 'Roles',
            icon: <VerifiedUserRoundedIcon />,
            path: '/roles',
            permission: PermissionCodes.Role,
          },
          { title: 'Users',
            icon: <PeopleRoundedIcon />,
            path: '/users',
            permission: PermissionCodes.User,
          },
        ],
      },
    /* {
      title: 'Cycle Search',
      icon: <CycleSearch />,
      path: '/cycleSearch',
    }, */
    ],
    [t]
  );
  // const useNavSections = () => {
  // console.log(sections);
  const { isAuthorized, assignedDivisions } = useAuth();

  const [authorizedSections, setAuthorizedSections] = useState([]);

  const updateNavStatus = useCallback(
    (navItem) => {
      if (!navItem) {
        return;
      }

      const { children } = navItem;
      if (!Array.isArray(children) || children.length === 0) {
        if (navItem.permission) {
          navItem.isAuthorized = isAuthorized({
            permissionCode: navItem.permission,
            right: PermissionRights.Search
          });

          if (navItem.isAuthorized && navItem.divisionId) {
            navItem.isAuthorized = !Array.isArray(assignedDivisions)
              || assignedDivisions.length === 0
              || assignedDivisions.includes(navItem.divisionId);
          }
        } else {
          navItem.isAuthorized = true;
        }

        return;
      }

      children.forEach((childNavItem) => updateNavStatus(childNavItem));

      navItem.isAuthorized = children.findIndex((x) => !!x.isAuthorized) >= 0;
    },
    [assignedDivisions, isAuthorized]
  );

  useEffect(() => {
    sections.forEach((navSection) => {
      if (!Array.isArray(navSection.items)) {
        return;
      }

      navSection.items.forEach((item) => updateNavStatus(item));
      navSection.isAuthorized = navSection.items.findIndex((x) => !!x.isAuthorized) >= 0;
    });

    setAuthorizedSections(sections);
  }, [sections, updateNavStatus]);

  return authorizedSections;
};

export default useNavSections;
