import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slice/searchPageSlice';

export const filterFields = {
  Location: 'Location',
  BusinessLine: 'BusinessLine',
  AssetType: 'AssetType',
  AssetSubType: 'AssetSubType',
  AssetName: 'AssetName',
  Make: 'Make',
  Model: 'Model',
  Country: 'Country',
  Active: 'Active',
};

const initialFilterState = {
  Location: null,
  BusinessLine: null,
  AssetType: null,
  AssetSubType: null,
  AssetName: '',
  Make: '',
  Model: '',
  Country: null,
  Active: true,
};

const initialPageState = {
  page: 0,
  pageSize: 100,
  sortColumn: 'AssetID',
  sortDirection: SortDirection.Asc,
};

export const sliceName = 'assetSearch';
const assetSearchSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState,
  initialPageState,
});

export const assetSearchActions = assetSearchSlice.actions;
export default assetSearchSlice.reducer;
