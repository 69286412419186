import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slice/searchPageSlice';

export const filterFields = {
  DateFrom: 'DateFrom',
  DateTo: 'DateTo',
  Organization: 'Organization',
  Program: 'Program',
  Product: 'Product',
  Zone: 'Zone',
};

const initialFilterState = {
  DateFrom: '',
  DateTo: '',
  Organization: null,
  Program: null,
  Product: null,
  Zone: null,
  DateFromMinDate: new Date(2024, 1, 26, 0, 0, 0, 0).toISOString(),
};

const initialPageState = {
  page: 0,
  pageSize: 100,
  sortColumn: 'LocationName',
  sortDirection: SortDirection.Asc,
};

export const sliceName = 'outstandingCARsSearch';
const outstandingCARsSearchSlice = createSearchPageSlice({
  sliceName,
  initialFilterState,
  filterFields,
  initialPageState
});

export const outstandingCARsSearchActions = outstandingCARsSearchSlice.actions;
export default outstandingCARsSearchSlice.reducer;
