import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slice/searchPageSlice';

export const filterFields = {
  ProgramName: 'ProgramName',
  Sponsor: 'Sponsor',
  ProgramProducts: 'ProgramProducts',
  Method: 'Method',
  TestItem: 'TestItem',
  Active: 'Active',
};

const initialFilterState = {
  ProgramName: '',
  Sponsor: null,
  ProgramProducts: null,
  Method: null,
  TestItem: null,
  Active: true,
};

const initialPageState = {
  page: 0,
  pageSize: 100,
  sortColumn: 'ProgramName',
  sortDirection: SortDirection.Asc,
};

export const sliceName = 'programSearch';
const programSearchSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState,
  initialPageState,

});

export const programSearchActions = programSearchSlice.actions;
export default programSearchSlice.reducer;
