import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';

import { styled } from '@mui/system';
import { Avatar, Badge, Box, Button, ButtonBase, Divider, ListItemIcon, ListItemText, MenuItem, Popover, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import LogoutIcon from '@mui/icons-material/Logout';

import { logout } from '../../../store/actions/authActions';
import useSettings from '../../../hooks/useSettings';
import useAuth from '../../../hooks/useAuth';
import TenantList from './TenantList';
// import UserImpersonateSelect from './UserImpersonateSelect';

const AvatarWrapper = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  height: 32,
  width: 32,
}));

const AccountPopover = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const anchorRef = useRef(null);
  const { user } = useAuth();
  const { FirstName, LastName, Email } = user || {};
  const avatarLetters = (FirstName || '').charAt(0) + (LastName || '').charAt(0);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { settings, saveSettings } = useSettings();

  // const hasImpesonatePermission = isAuthorized({ permissionCode: PermissionCodes.Impersonate });
  // let impersonatedUsername = '';
  // if (impersonatedUser) {
  //   impersonatedUsername = impersonatedUser.Username;
  // }

  const { compact } = settings || {};

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await dispatch(logout());
      navigate('/');
    } catch (err) {
      console.error(err);
      toast.error('Unable to logout.');
    }
  };

  const openAccountSettingHandler = () => {
    navigate('/account');
    handleClose();
  };

  const switchCompactModeHandler = () => {
    const newValues = {
      ...settings,
      compact: !compact,
    };

    saveSettings(newValues);
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Badge invisible overlap="circular" variant="standard" badgeContent={<PersonPinIcon size="small" color="action" />}>
          <AvatarWrapper>{avatarLetters}</AvatarWrapper>
        </Badge>
      </Box>

      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        // keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            {FirstName} {LastName}
          </Typography>

          <Typography color="textSecondary" variant="subtitle2">
            {Email || ''}
          </Typography>
        </Box>

        <Divider />

        {/* {hasImpesonatePermission && !isImpersonateing && (
          <>
            <Box sx={{ m: 1.5 }}>
              <UserImpersonateSelect />
            </Box>
          </>
        )} */}

        <Box sx={{ m: 1.5 }}>
          <TenantList />
        </Box>

        <Divider />

        <Box sx={{ m: 1, ml: 0 }}>
          {/* <MenuItem component={RouterLink} to="/users/profile">
            <ListItemIcon>
              <UserIcon fontSize="small" />
            </ListItemIcon>

            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  Profile
                </Typography>
              }
            />
          </MenuItem> */}

          <MenuItem sx={{ py: 1 }} onClick={openAccountSettingHandler}>
            <ListItemIcon>
              <AccountCircleIcon fontSize="small" />
            </ListItemIcon>

            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  {t('Btn_Profile')}
                </Typography>
              }
            />
          </MenuItem>
        </Box>

        <Divider />

        <Box sx={{ m: 1, ml: 0 }}>
          <MenuItem sx={{ py: 1 }} onClick={switchCompactModeHandler}>
            <ListItemIcon>
              {compact && <OpenInFullIcon fontSize="small" />}
              {!compact && <CloseFullscreenIcon fontSize="small" />}
            </ListItemIcon>

            <ListItemText
              primary={
                <Typography color="textPrimary" variant="subtitle2">
                  {compact ? t('Label_Stretch') : t('Label_Compact')}
                </Typography>
              }
            />
          </MenuItem>
        </Box>

        <Divider />

        <Box sx={{ p: 2 }}>
          <Button color="primary" fullWidth onClick={handleLogout} variant="outlined" startIcon={<LogoutIcon size="small" />}>
            {t('Btn_Logout')}
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default AccountPopover;
