import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slice/searchPageSlice';

export const filterFields = {
  AccreditationBody: 'AccreditationBody',
  Country: 'Country',
  Location: 'Location',
  Type: 'Type',
  Status: 'Status',
};

const initialFilterState = {
  AccreditationBody: '',
  Country: null,
  Type: null,
  Location: null,
  Status: 'All',
};

const initialPageState = {
  page: 0,
  pageSize: 100,
  sortColumn: 'AccreditationBodyName',
  sortDirection: SortDirection.Asc,
};

export const sliceName = 'accreditationBodiesSearch';

const AccreditationBodiesSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState,
  initialPageState
});

export const accreditationBodiesActions = AccreditationBodiesSlice.actions;
export default AccreditationBodiesSlice.reducer;
