import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slice/searchPageSlice';

export const filterFields = {
  RoleName: 'RoleName',
  Active: 'Active'
};

export const sliceName = 'roleSearch';
const roleSearchSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState: {
    RoleName: '',
    Active: true,
  },
  initialPageState: {
    page: 0,
    pageSize: 50,
    sortColumn: 'RoleName',
    sortDirection: SortDirection.Asc
  },
});

export const roleSearchActions = roleSearchSlice.actions;
export default roleSearchSlice.reducer;
