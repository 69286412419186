import { SortDirection } from '../../../../utils/constants';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slice/searchPageSlice';

export const filterFields = {
  Zone: 'Zone',
  BusinessLine: 'BusinessLine',
  Country: 'Country',
  Location: 'Location',
  Products: 'Products',
  From: 'From',
  To: 'To'
};

const initialFilterState = {
  Zone: null,
  BusinessLine: null,
  Country: null,
  Location: null,
  Products: [],
  From: '',
  To: '',
  FromMinDate: new Date(2024, 1, 26, 0, 0, 0, 0).toISOString(),
};

const initialPageState = {
  page: 0,
  pageSize: 100,
  sortColumn: 'TestMethodName',
  sortDirection: SortDirection.Asc,
};

export const sliceName = 'zCarPercentageReport';
const zCarPercentageReportSlice = createSearchPageSlice({
  sliceName,
  initialFilterState,
  filterFields,
  initialPageState,
});

export const zCarPercentageReportActions = zCarPercentageReportSlice.actions;
export default zCarPercentageReportSlice.reducer;
